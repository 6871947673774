import request from '@/utils/http';

/**
 * 会员列表
 * @param payload
 */
 export function user_list(payload) {
    return request({ url: '/user/Index/lists', method: 'get', params:payload})
}

export function message_list(payload) {
    return request({ url: '/user/Index/message', method: 'get', params:payload})
}
export function message_delete(payload) {
    return request({ url: '/user/Index/delete_message', method: 'get', params:payload})
}
export function message_change(payload) {
    return request({ url: '/user/Index/change_message', method: 'get', params:payload})
}
export function message_replay(payload) {
    return request({ url: '/user/Index/message_replay', method: 'post', params:payload})
}
/**
 * 添加会员
 * @param payload
 */
export function user_create(payload) {
    return request({ url: '/user/Index/create', method: 'post', data: payload })
}

/**
 * 更新会员
 * @param payload
 */
export function user_update(payload) {
    return request({ url: '/user/Index/update', method: 'post', data: payload })
}

/**
 * 删除会员
 * @param payload
 */
export function user_del(payload) {
    return request({ url: '/user/Index/delete/' + payload.id, method: 'delete', data: payload })
}

/**
 * 会员等级列表
 * @param payload
 */
export function user_level_list(payload) {
    return request({ url: '/user/Level/lists', method: 'get', params:payload})
}

/**
 * 会员等级列表(所有)
 * @param payload
 */
 export function user_level_all(payload) {
    return request({ url: '/user/Level/listsAll', method: 'get', params:payload})
}

/**
 * 添加会员等级
 * @param payload
 */
export function user_level_create(payload) {
    return request({ url: '/user/Level/create', method: 'post', data: payload })
}

/**
 * 更新会员等级
 * @param payload
 */
export function user_level_update(payload) {
    return request({ url: '/user/Level/update', method: 'post', data: payload })
}

/**
 * 删除会员等级
 * @param payload
 */
export function user_level_del(payload) {
    return request({ url: '/user/Level/delete/' + payload.id, method: 'delete', data: payload })
}

/**
 * 会员标签列表
 * @param payload
 */
export function user_label_list(payload) {
    return request({ url: '/user/Label/lists', method: 'get', params: payload })
}

/**
 * 添加会员标签
 * @param payload
 */
export function user_label_create(payload) {
    return request({ url: '/user/Label/create', method: 'post', data: payload })
}

/**
 * 更新会员标签
 * @param payload
 */
export function user_label_update(payload) {
    return request({ url: '/user/Label/update', method: 'post', data: payload })
}

/**
 * 删除会员标签
 * @param payload
 */
export function user_label_del(payload) {
    return request({ url: '/user/Label/delete/' + payload.id, method: 'delete', data: payload })
}

/**
 * 会员标签列表(所有)
 * @param payload
 */
 export function user_label_all(payload) {
    return request({ url: '/user/Label/listsAll', method: 'get', params:payload})
}

/**
 * 会员群体列表
 * @param payload
 */
 export function user_cluster_list(payload) {
    return request({ url: '/user/Cluster/lists', method: 'get', params: payload })
}

/**
 * 添加会员群体
 * @param payload
 */
 export function user_cluster_create(payload) {
    return request({ url: '/user/Cluster/create', method: 'post', data: payload })
}

/**
 * 更新会员群体
 * @param payload
 */
export function user_cluster_update(payload) {
    return request({ url: '/user/Cluster/update', method: 'post', data: payload })
}

/**
 * 删除会员群体
 * @param payload
 */
export function user_cluster_del(payload) {
    return request({ url: '/user/Cluster/delete/' + payload.id, method: 'delete', data: payload })
}
 /**
 * 会员导入记录
 * @param payload
 */
  export function user_import_record(payload) {
    return request({ url: '/user/Import/record', method: 'get', params: payload })
}
/**
 * 会员导入
 * @param payload
 */
 export let upload = process.env.VUE_APP_API_BASE_URL + '/user/Import/member';

 /**
 * 会员导入模版下载
 * @param payload
 */
 export function user_import_file(payload) {
    return request({ url: '/user/Import/downloadMemberFile', method: 'get', params: payload })
}