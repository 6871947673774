<template>
  <a-modal
    v-model:visible="visible"
    title="编辑用户"
    @ok="handleSubmitUpdate"
    @cancel="onCancel"
    :width="720"
  >
    <a-form ref="formRef" v-bind="layout" :model="formState" :rules="rules">
      <a-form-item has-feedback label="昵称" name="nick_name">
        <a-input
          v-model:value="formState.nick_name"
          type="text"
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item has-feedback label="账号" name="user_name">
        <a-input
          v-model:value="formState.user_name"
          type="text"
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item has-feedback label="会员等级" name="user_level">
        <a-select
            default-value="0"
             placeholder="请选择等级"
             v-model:value="formState.user_level"
             >
            <a-select-option :value="0">无</a-select-option>
                <a-select-option
                    v-for="(item, index) in levelListData"
                    :key="index"
                    :value="item.value"
                    >{{ item.title }}</a-select-option
                  >
        </a-select>
      </a-form-item>
      <a-form-item  label="标签" name="labels">
        <a-tree-select
          v-model:value="formState.labels"
          :tree-data="labelListData"
          tree-checkable
          :show-checked-strategy="SHOW_PARENT"
          placeholder="请选择标签"
        />
      </a-form-item>
      <a-form-item has-feedback label="登录密码" name="password">
        <a-input
          v-model:value="formState.password"
          type="password"
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item has-feedback label="手机号" name="phone">
        <a-input
          v-model:value="formState.phone"
          type="text"
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item has-feedback label="邮箱" name="email">
        <a-input
          v-model:value="formState.email"
          type="text"
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item has-feedback label="状态" name="status">
        <a-switch
          v-model:checked="formState.status"
          checked-children="正常"
          un-checked-children="禁用"
        />
      </a-form-item>
      <!-- <a-form-item has-feedback label="排序" name="sort" extra="数字越小越靠前">
        <a-input
          class="mini-input"
          type="number"
          v-model:value="formState.sort"
        />
      </a-form-item> -->
    </a-form>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, ref, toRaw } from "vue";
import { message } from "ant-design-vue";
import * as Api from "@/addons/user/api";
import { TreeSelect } from 'ant-design-vue';
export default defineComponent({
  name: "update-user",
  props: ["levelList","labelList"],
  setup(props, context) {
    console.log(props);
    const SHOW_PARENT = TreeSelect.SHOW_PARENT;
    // 显示弹框
    const visible = ref(false);

    // 获取等级数据
    const levelListData = ref();

    // 获取标签数据
    const labelListData = ref();

    // 打开弹框
    const showFromModal = (item) => {
      if (item != null) {
        formState.uid = item.uid;
        formState.user_name = item.user_name;
        formState.nick_name = item.nick_name;
        formState.password = "";
        formState.status = item.status?true:false;
        formState.phone = item.phone;
        formState.email = item.email;
        formState.user_level = item.user_level;
        formState.labels = item.user_label?item.user_label.split(","):[];
      }
      levelListData.value = onFormatLevelList(props.levelList);
      labelListData.value = onFormatLabelList(props.labelList);
      visible.value = true;
    };

    const formRef = ref();

    const formState = reactive({
      nick_name: "",
      user_name: "",
      password: "",
      phone: "",
      email: "",
      status: true,
      user_level:0
    });

    function onFormatLabelList(list) {
      const data = [];
      list.forEach((item) => {
        // 新的元素
        const netItem = {
          title: item.label_name,
          key: item.label_id,
          value: item.label_id,
        };
        data.push(netItem);
      });
      console.log('label_List');
      console.log(data);
      return data;
    }

    // 格式化等级数据
    function onFormatLevelList(list) {
      const data = [];
      list.forEach((item) => {
        // 新的元素
        const netItem = {
          title: item.level_name,
          value: item.level_id,
        };
        data.push(netItem);
      });
      return data;
    }

    let validatePass = async (_rule, value) => {
      if (value === "" && formState.check_password === "") {
        return Promise.resolve();
      } else {
        if (formState.check_password !== "") {
          formRef.value.validateFields("check_password");
        }
        return Promise.resolve();
      }
    };

    const rules = {
      nick_name: [
        {
          required: true,
          min: 2,
          message: "请输入至少2个字符",
          trigger: "change",
        },
      ],
      user_name: [
        {
          required: true,
          min: 2,
          message: "请输入至少2个字符",
          trigger: "change",
        },
      ],
      password: [
        {
          required: false,
          validator: validatePass,
          trigger: "change",
        },
      ],
    };

    const layout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    };

    const handleSubmitUpdate = () => {
      formRef.value
        .validate()
        .then(() => {
          console.log("values", formState, toRaw(formState));
          Api.user_update(formState).then(
            (res) => {
              message.success(res.message);
              visible.value = false;
              // 通知父组件刷新列表
              context.emit("handleSubmit");
            },
            (error) => {
              message.error(error);
            }
          );
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const onCancel = () => {
      formRef.value.resetFields();
    };

    return {
      formState,
      formRef,
      rules,
      layout,
      visible,
      showFromModal,
      onCancel,
      handleSubmitUpdate,
      onFormatLevelList,
      levelListData,
      onFormatLabelList,
      labelListData,
      SHOW_PARENT
    };
  },
});
</script>